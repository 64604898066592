<template>
  <div>
    <section
      v-if="hasRelatedBundle"
      class="upsell"
    >
      <div class="items">
        <div
          v-for="(item, index) in bundleProducts"
          :key="index"
          class="item"
        >
          <ResponsiveImage
            :lazyload="true"
            :mobile-src="item.mainImage"
            :desktop-src="item.mainImage"
            :alt="item.name || 'Product image'"
            class="image"
          />
          <PlusIcon
            class="plus-icon"
            :class="bundleProducts.length - 1 === index ? 'hidden' : 'visible'"
          />
        </div>
      </div>
      <button
        class="button outline blue wide"
        @click="goToBundle"
      >
        <span v-if="hasBundleDiscount">{{ $t('Bundle_Upsell_Button', { savings }) }}</span>
      </button>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PlusIcon from '~/static/images/plus.svg'

export default {
  name: 'BundleUpsellAdvanced',
  components: {
    PlusIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasRelatedBundle: !!this.product.relatedBundles?.length,
      bundleProducts: [],
      bundleDetails: undefined,
    }
  },
  computed: {
    hasBundleDiscount() {
      return !!this.bundleDetails?.bundleDiscount?.discount
    },
    savings() {
      const discount = this.bundleDetails?.bundleDiscount?.discount || 0
      const currency = this.bundleDetails?.bundleDiscount?.currency || ''
      return `${Math.round(discount)} ${currency}`
    },
  },
  mounted() {
    if (this.hasRelatedBundle) {
      this.fetchBundleData(this.product.relatedBundles[0]?.product)
    }
  },
  methods: {
    ...mapActions('centra-product', ['lookupBundle']),
    async fetchBundleData(bundleId) {
      try {
        const bundleData = await this.lookupBundle(bundleId)
        this.bundleDetails = bundleData.bundle
        this.bundleProducts = bundleData.sectionProducts.map((product) => ({
          url: product.url,
          mainImage: product.mainImage ?? product.media[0]?.sources?.original?.url,
        }))
      } catch (error) {
        console.error('Error fetching bundle data on PDP', error)
      }
    },

    goToBundle() {
      this.$router.push(this.bundleDetails.uri)
    },
  },
}
</script>

<style lang="scss" scoped>
.upsell {
  margin: 1.6rem 2.4rem;
  padding-top: 1.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;
  border-radius: 0.3rem;
  .items {
    display: flex;
    gap: 0.8rem;

    .item {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      .image {
        width: 6rem;
        aspect-ratio: 3 / 4;

        ::v-deep {
          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .plus-icon {
        width: 1.2rem;
        height: 1.2rem;

        &.hidden {
          display: none;
        }

        &.visible {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: $tablet-landscape) {
    padding: 2.4rem;
    margin: 2.4rem 0 4rem 0;
    flex-direction: row;
    align-items: center;
    border: 1px solid $grey;
    background-color: $white;

    button {
      margin-left: auto;
      max-width: fit-content;
    }
  }
}
</style>
