<template>
  <div class="bundle-details">
    <div class="header">
      <h2 v-if="hasBundleInfo && !loading">{{ numberOfItems }} {{ $t('Bundle_Details_Number_Of_Items') }}</h2>
      <p v-if="hasBundleDiscount && !loading">{{ $t('Bundle_Details_Savings_Prefix') }} {{ savings }} {{ $t('Bundle_Details_Savings_Suffix') }}</p>
    </div>

    <div
      v-if="loading"
      class="skeleton-loader"
    >
      <div class="skeleton-header"></div>
      <div
        v-for="index in 2"
        :key="`skeleton-${index}`"
        class="skeleton-section"
      ></div>
    </div>

    <div
      v-else-if="sections.length"
      class="bundle-sections"
    >
      <BundleSection
        v-for="(section, index) in sections"
        :key="`bundle-section-${section.section || index}`"
        :section="section"
        :desktop="desktop"
        @bundle-section-active-swatch-changed="onActiveSwatchChanged"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BundleSection from './BundleSection.vue'

export default {
  name: 'BundleDetails',
  components: { BundleSection },
  props: {
    product: { type: Object, required: true },
    productId: { type: String, required: true },
    desktop: { type: Boolean, default: false },
  },
  data: () => ({
    bundleDetails: undefined,
    bundleInfo: undefined,
    bundleDiscount: undefined,
    activeProducts: {},
    loading: true,
    selectedItems: [],
  }),
  computed: {
    hasBundleInfo() {
      return !!this.bundleInfo?.sections?.length
    },
    numberOfItems() {
      const sections = this.bundleInfo?.sections

      if (Array.isArray(sections)) {
        const totalQuantity = sections.reduce((total, section) => total + (section.quantity || 0), 0)
        return totalQuantity
      }

      return 0
    },
    hasBundleDiscount() {
      // Check if bundleDiscount exists and if bundle type is flexible
      return !!this.bundleDiscount?.discount && this.bundleInfo?.type === 'flexible'
    },
    savings() {
      const discount = Math.floor(this.bundleDiscount?.discount || 0);
      const currency = this.bundleDiscount?.currency || ''
      return `${discount} ${currency}`
    },
    sections() {
      if (!this.bundleDetails || !this.bundleDetails.sectionProducts || !this.bundleDetails.bundle?.bundleInfo?.sections) {
        return []
      }

      const productMap = Object.fromEntries(this.bundleDetails.sectionProducts.map((product) => [product.product, product]))

      return this.bundleDetails.bundle.bundleInfo.sections.map((section) => ({
        ...section,
        products: section.products.map((productId) => productMap[productId] || {}),
      }))
    },
  },
  async mounted() {
    try {
      this.bundleDetails = await this.fetchBundleData(this.product?.product)
      this.bundleInfo = this.bundleDetails?.bundle?.bundleInfo
      this.bundleDiscount = this.bundleDetails?.bundle?.bundleDiscount
    } catch (error) {
      console.error('Error fetching bundle details:', error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('centra-product', ['lookupBundle']),
    async fetchBundleData(bundleId) {
      try {
        return await this.lookupBundle(bundleId)
      } catch (error) {
        console.error('Error fetching bundle data:', error)
        return null
      }
    },
    onActiveSwatchChanged(payload) {
      this.$emit('bundle-section-active-swatch-changed', payload)
    },
  },
}
</script>

<style scoped lang="scss">
.bundle-details {
  padding: 1.6rem 2.4rem;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;

    h2 {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.6;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.6;
      color: $blue;
    }
  }

  .bundle-sections {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .skeleton-loader {
    .skeleton-header {
      width: 60%;
      height: 1.4rem;
      background-color: $grey;
      border-radius: 4px;
      margin-bottom: 1.6rem;
    }

    .skeleton-section {
      width: 100%;
      height: 8rem;
      background-color: $grey;
      border-radius: 4px;
      margin-bottom: 1.6rem;
    }
  }
}

@media screen and (min-width: $tablet-landscape) {
  .bundle-details {
    padding: 0rem;

    .header {
      margin-bottom: 1.6rem;
    }

    .bundle-sections {
      gap: 1.6rem;
    }

    .skeleton-loader {
      .skeleton-header {
        width: 40%;
        height: 1.6rem;
      }

      .skeleton-section {
        height: 12rem;
      }
    }
  }
}
</style>
