<template>
  <div class="upsell-simple">
    <button
      v-if="hasRelatedBundle"
      class="button outline blue wide"
      @click="goToBundle"
    >
      <span v-if="hasBundleDiscount">{{ $t('Bundle_Upsell_Button', { savings }) }}</span>
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'BundleUpsellSimple',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasRelatedBundle: !!this.product.relatedBundles?.length,
      bundleDetails: undefined,
    }
  },
  computed: {
    hasBundleDiscount() {
      return !!this.bundleDetails?.bundleDiscount?.discount
    },
    savings() {
      const discount = this.bundleDetails?.bundleDiscount?.discount || 0
      const currency = this.bundleDetails?.bundleDiscount?.currency || ''
      return `${Math.round(discount)} ${currency}`
    },
  },
  mounted() {
    if (this.hasRelatedBundle) {
      this.fetchBundleData(this.product.relatedBundles[0]?.product)
    }
  },
  methods: {
    ...mapActions('centra-product', ['lookupBundle']),
    async fetchBundleData(bundleId) {
      try {
        const bundleData = await this.lookupBundle(bundleId)
        this.bundleDetails = bundleData.bundle
        this.bundleProducts = bundleData.sectionProducts.map((product) => ({
          url: product.url,
          mainImage: product.mainImage ?? product.media[0]?.sources?.original?.url,
        }))
      } catch (error) {
        console.error('Error fetching bundle data on PDP', error)
      }
    },
    goToBundle() {
      this.$router.push(this.bundleDetails.uri)
    },
  },
}
</script>

<style lang="scss" scoped>
.upsell-simple {
  padding: 2.4rem var(--side-padding);
  padding-top: 3.2rem;
  .button {
    width: 100%;
  }
}

@media screen and (min-width: $tablet-landscape) {
  .upsell-simple {
    padding: 0rem;
  }
}
</style>
